<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
            :value="value"
            scrollable
            max-width="1200px"
            persistent>
    <v-card v-if="matricula">
      <v-card-title>
        Notificações da Matrícula
        <v-spacer/>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-0">
        <section class="pa-4">
          <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field dense disabled hide-details label="Cliente" :value="matricula.nomeIdCliente"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field dense disabled hide-details label="Local" :value="matricula.descricaoIdLocal"/>
              </v-col>
            </v-row>
            <v-textarea class="mt-5" rows="6" label="Notificação" filled
                        :rules="[
                            v => this.$validators.string.greaterThanOrEquals(v, 0),
                            v => this.$validators.string.lessThanOrEquals(v, 1000),
                          ]" v-model="matricula.descNotificacoes"/>
          </v-form>
        </section>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="$emit('input', false)">Cancelar</v-btn>
        <v-btn  color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
      </v-card-actions>          
    </v-card>
  </v-dialog>
</template>

<script>
import {updateNotificacao} from "../../../api/matriculas";
import {ApiError} from "../../../api/core";

export default {
  name: "NotificacoesMatriculaDialog",
  props: ['value', 'matricula'],
  data() {
    return {
      formValid: false
    }
  },
  methods: {
    async save() {
      try {
        if(this.$refs.form.validate()) {
          await updateNotificacao(this.matricula.id, this.matricula.descNotificacoes);
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Notificação atualizada com sucesso.`, {
            timeout: 3000
          })
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível atualizar a Notificação.`, {
            timeout: 6000
          })
        }
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>