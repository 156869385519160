<template>
  <div>
    <component :is="container" v-model="opened"
               fullscreen
               scrollable
               :close-on-content-click="false">
      <template v-slot:activator="{on, attrs}">
        <v-text-field :label="label"
                      :rules="rules"
                      :disabled="disabled"
                      :value="itemDescValue"
                      dense
                      :clearable="hiddenClear !== true"
                      @click:clear="resetValues"
                      readonly v-on="on" v-bind="attrs"/>
      </template>
      <v-card>
        <v-card-title class="pa-0">
          <div class="d-flex justify-space-between flex-grow-1 flex-sm-grow-0">
            <h3 class="ma-2 subtitle-1">{{popupLabel || label}}</h3>
            <v-btn fab text small class="hidden-sm-and-up" @click="opened = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="my-2 mx-2 d-flex flex-grow-1">
            <v-text-field dense solo placeholder="Buscar"
                          :clearable="false"
                          class="flex-grow-1"
                          @click:clear="search"
                          @keypress.enter="search"
                          v-model="searchInput"
                          hide-details/>
            <v-btn class="ml-2" color="primary" @click="search">Buscar</v-btn>
          </div>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto pa-0" :style="listStyle">
          <v-expand-transition>
            <v-progress-linear indeterminate color="primary" v-if="loading"/>
          </v-expand-transition>
          <v-list dense class="pa-0" v-if="elements.length > 0">

            <v-tooltip top v-for="i of elements" :key="i[itemKey]" :disabled="true">
              <template v-slot:activator="{on}">
                <v-list-item :input-value="i[itemKey] === itemKeyValue" active-class="primary--text" link class="bb" dense @click="select(i)" v-on="on">
                  <v-list-item-action>
                    <strong>#{{i[itemKey]}}</strong>
                  </v-list-item-action>
                  <v-list-item-title>
                    {{i[itemText]}}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <span>
                {{i[itemText]}}
              </span>
            </v-tooltip>
          </v-list>
          <div v-else>
            <slot name="empty"/>
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="d-flex flex-column pa-0">
          <v-pagination v-model="page"
                        @input="changePage"
                        :length="size"/>
        </v-card-actions>
      </v-card>
    </component>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import {VDialog, VMenu} from "vuetify/lib/components";
export default {
  name: "SearchBoxOld",
  props: ['value','itemText','disabled', 'label', 'popupLabel', 'itemKey', 'loaderFn', 'rules', 'hiddenClear', 'emitAllProperties'],
  data() {
    return {
      opened: false,
      elements: [],
      page: 1,
      size: 0,
      searchInput: '',
      searchInputConfirmed: '',
      loading: true
    }
  },
  computed: {
    container() {
      if(this.$vuetify.breakpoint.mobile) {
        return VDialog;
      } else {
        return VMenu;
      }
    },
    listStyle() {
      if(this.$vuetify.breakpoint.mobile) {
        return '';
      } else {
        return 'max-height: 200px';
      }
    },
    itemKeyValue() {
      return this.value ? this.value[this.itemKey] : null;
    },
    itemDescValue() {
      return this.value ? this.value[this.itemText] : null;
    }
  },
  methods: {

    resetValues() {
      this.$emit('input', null);
      this.$emit(`update:${this.itemKey}`,null);
      this.$emit(`update:${this.itemText}`,null);
      if(this.emitAllProperties && this.item != null) {
        Object.keys(item).forEach(key => {
          if(key !== this.itemKey && key !== this.itemText) {
            this.$emit(`update:${key}`,item[key]);
          }
        });
      }
    },

    select(item) {
      this.$emit('input', item);
      this.$emit(`update:${this.itemKey}`,item[this.itemKey]);
      this.$emit(`update:${this.itemText}`,item[this.itemText]);
      if(this.emitAllProperties) {
        Object.keys(item).forEach(key => {
          if(key !== this.itemKey && key !== this.itemText) {
            this.$emit(`update:${key}`,item[key]);
          }
        });
      }
      this.opened = false;
    },


    search() {
      this.searchInputConfirmed = this.searchInput;
      this.page = 1;
      this.refresh();
    },
    async refresh() {
      this.loading = true;
      try {
        const page = await this.loaderFn(this.page, this.searchInput);
        this.elements = page.elements;
        this.page = page.currentPage;
        this.size = page.totalPages;
      } catch (err) {
        //console.log(err);
      } finally {
        this.loading = false;
      }
    },
    changePage: debounce(function (page) {
      this.page = page;
      this.refresh();
    }, 300),
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>