var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"value":_vm.value,"scrollable":"","max-width":"500px","persistent":""}},[(_vm.matricula)?_c('v-card',[_c('v-slide-y-transition',[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"color":"primary","indeterminate":"","height":"10"}})],1),_c('v-card-title',[_vm._v(" Histórico da Matrícula "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('section',{staticClass:"pa-4"},[_c('v-form',{ref:"form"},[_c('h3',{staticClass:"mt-3 mb-5"},[_vm._v("Dados da Matrícula")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","disabled":"","hide-details":"","label":"Cliente","value":_vm.matricula.nomeIdCliente}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","disabled":"","hide-details":"","label":"Turma","value":_vm.matricula.descricaoIdAula}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"dense":"","hide-details":"","label":"Status","disabled":"","value":_vm.data.sts,"items":[
                    {value: 'A', text: 'Ativo'},
                    {value: 'I', text: 'Inativo'} ]}})],1)],1),_c('v-textarea',{staticClass:"mt-5",attrs:{"rows":"2","label":"Motivo","filled":"","rules":[
                          this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); } ]},model:{value:(_vm.data.motivo),callback:function ($$v) {_vm.$set(_vm.data, "motivo", $$v)},expression:"data.motivo"}}),_c('v-btn',{attrs:{"small":"","color":"primary","outlined":"","block":""},on:{"click":_vm.gravar}},[_vm._v(_vm._s(_vm.data.sts === 'A' ? 'Inativar Matricula' : 'Ativar Mátricula'))])],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
    disableItemsPerPage: true,
    itemsPerPageOptions: [_vm.itemsPerPage],
  },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event}},scopedSlots:_vm._u([{key:"item.stsMatricula",fn:function(ref){
  var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.stsMatricula === 'A' ? 'Ativo' : 'Inativo'))])]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
  var items = ref.items;
  var isSelected = ref.isSelected;
  var select = ref.select;
return [(items.length === 0)?_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[_c('p',[_vm._v("Não há dados disponíveis")])])],1):_vm._e(),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-list-item',{key:item.id,staticClass:"list-item"},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.motivo)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.data)}})],1),_c('v-list-item-action-text',[_c('v-chip',{attrs:{"small":"","input-value":"false","outlined":""}},[_vm._v(_vm._s(item.stsMatricula === 'A' ? 'Ativo' : 'Inativo')+" ")])],1)],1)}),1)]}}:null],null,true)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }