<template>
  <v-row>
    <!--dados da matricula-->
    <v-col cols="12" class="">
      <v-card class="px-3">
        <v-card-text class="subtitle-1 font-weight-light">
          <v-row>
            <v-col cols="12" md="8">
              <search-box
                  label="* Cliente"
                  popup-label="Selecione um Cliente"
                  :id.sync="value.idCliente"
                  :nome.sync="value.nomeIdCliente"
                  :hidden-clear="true"
                  :value="cliente"
                  :loader-fn="loaderClientes"
                  item-key="id"
                  item-text="nome"
                  :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum Cliente encontrado
                  </p>
                </template>
              </search-box>
            </v-col>
            <v-col cols="12" md="4">
              <search-box
                  label="* Local"
                  :hidden-clear="true"
                  popup-label="Selecione qual o local"
                  :id.sync="value.idLocal"
                  :descricao.sync="value.descricaoIdLocal"
                  :value="local"
                  :loader-fn="loaderLocal"
                  item-key="id"
                  item-text="descricao"
                  :rules="[
                        this.$validators.notNullOrUndefined
                    ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum local encontrado
                  </p>
                </template>
              </search-box>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="">
      <v-card class="px-3">
        <v-card-text class="subtitle-1 font-weight-light">
          <pagamento-anuidade-form v-model="value"/>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="">
      <v-card class="px-3">
        <v-card-title>
          <v-icon large left color="red">mdi-bell-badge-outline</v-icon>
          <span class="title font-weight-bold">Notificação</span>
        </v-card-title>
        <v-card-text class="subtitle-1 font-weight-light">
          <v-row>
            <v-col cols="12" sm="12">
            <v-textarea rows="2" label="" filled
                        :rules="[
                          v => this.$validators.string.greaterThanOrEquals(v, 0),
                          v => this.$validators.string.lessThanOrEquals(v, 1000),
                        ]" 
                        v-model="value.descNotificacoes"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>              
  </v-row>
</template>

<script>
import SearchBox from "../common/SearchBox";
import {findAll as findAllClientes} from "../../../api/clientes";
import {findAll as findAllLocal} from '../../../api/locais';
import SearchBoxOld from "../common/SearchBoxOld";
import SearchBoxMultiple from "../common/SearchBoxMultiple";
import PagamentoAnuidadeForm from '../forms/PagamentoAnuidadeForm';

export default {
  name: "MatriculaForm",
  components: {SearchBoxMultiple, SearchBoxOld, SearchBox, PagamentoAnuidadeForm},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
    }
  }, 
  computed: {
    cliente() {
      if (this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.nomeIdCliente
      }
    },
    local() {
      if (this.value.idLocal == null) return null;
      return {
        id: this.value.idLocal,
        descricao: this.value.descricaoIdLocal
      }
    },
  },
  watch: {
  },
  methods: {
    loaderClientes(page, search) {
      return findAllClientes(page, {
        nome: search
      })
    },
    loaderLocal(page, search) {
      return findAllLocal(page, {
        descricao: search
      })
    },
  }
}
</script>

<style scoped lang="scss">

</style>