var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-3"},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-light"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('search-box',{attrs:{"label":"* Cliente","popup-label":"Selecione um Cliente","id":_vm.value.idCliente,"nome":_vm.value.nomeIdCliente,"hidden-clear":true,"value":_vm.cliente,"loader-fn":_vm.loaderClientes,"item-key":"id","item-text":"nome","rules":[
                    this.$validators.notNullOrUndefined
                ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCliente", $event)},"update:nome":function($event){return _vm.$set(_vm.value, "nomeIdCliente", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Cliente encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('search-box',{attrs:{"label":"* Local","hidden-clear":true,"popup-label":"Selecione qual o local","id":_vm.value.idLocal,"descricao":_vm.value.descricaoIdLocal,"value":_vm.local,"loader-fn":_vm.loaderLocal,"item-key":"id","item-text":"descricao","rules":[
                      this.$validators.notNullOrUndefined
                  ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idLocal", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricaoIdLocal", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum local encontrado ")])]},proxy:true}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-3"},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-light"},[_c('pagamento-anuidade-form',{model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-3"},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":"","color":"red"}},[_vm._v("mdi-bell-badge-outline")]),_c('span',{staticClass:"title font-weight-bold"},[_vm._v("Notificação")])],1),_c('v-card-text',{staticClass:"subtitle-1 font-weight-light"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"rows":"2","label":"","filled":"","rules":[
                        function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 0); },
                        function (v) { return this$1.$validators.string.lessThanOrEquals(v, 1000); } ]},model:{value:(_vm.value.descNotificacoes),callback:function ($$v) {_vm.$set(_vm.value, "descNotificacoes", $$v)},expression:"value.descNotificacoes"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }