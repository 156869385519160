<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
            :value="value"
            scrollable
            max-width="500px"
            persistent>
    <v-card v-if="matricula">
      <v-slide-y-transition>
        <v-progress-linear color="primary" v-show="loading" indeterminate height="10"/>
      </v-slide-y-transition>
      <v-card-title>
        Histórico da Matrícula
        <v-spacer/>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-0">
        <section class="pa-4">
          <v-form ref="form">
            <h3 class="mt-3 mb-5">Dados da Matrícula</h3>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field dense disabled hide-details label="Cliente" :value="matricula.nomeIdCliente"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field dense disabled hide-details label="Turma" :value="matricula.descricaoIdAula"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select dense hide-details label="Status" disabled :value="data.sts" :items="[
                      {value: 'A', text: 'Ativo'},
                      {value: 'I', text: 'Inativo'},
                  ]"/>
              </v-col>
            </v-row>
            <v-textarea class="mt-5" rows="2" label="Motivo" filled
                        :rules="[
                            this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 200),
                          ]" v-model="data.motivo"/>
            <v-btn @click="gravar" small color="primary" outlined block>{{ data.sts === 'A' ? 'Inativar Matricula' : 'Ativar Mátricula' }}</v-btn>
          </v-form>

        </section>

        <v-divider/>
        <v-data-table :headers="headers"
                      dense
                      fixed-header
                      :options.sync="pageOptions"
                      :server-items-length="totalElements"
                      :disable-sort="true"
                      :hide-default-header="$vuetify.breakpoint.mobile"
                      :footer-props="{
      disableItemsPerPage: true,
      itemsPerPageOptions: [itemsPerPage],
    }"
                      :items="elements" :loading="loading">
          <template v-slot:item.stsMatricula="{item}">
            <p class="ma-0">{{ item.stsMatricula === 'A' ? 'Ativo' : 'Inativo' }}</p>
          </template>


          <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
            <v-card v-if="items.length === 0" class="elevation-0">
              <v-card-text class="text-center">
                <p>Não há dados disponíveis</p>
              </v-card-text>
            </v-card>
            <v-list dense class="pa-0">
              <v-list-item v-for="item of items" :key="item.id"
                           class="list-item">

                <v-list-item-content>
                  <v-list-item-title v-text="item.motivo"/>
                  <v-list-item-subtitle v-text="item.data"/>
                </v-list-item-content>
                <v-list-item-action-text>
                  <v-chip small input-value="false" outlined>{{
                      item.stsMatricula === 'A' ? 'Ativo' : 'Inativo'
                    }}
                  </v-chip>
                </v-list-item-action-text>
              </v-list-item>
            </v-list>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {findAll, create} from '../../../api/historicoMatriculas';

export default {
  name: "HistoricoMatriculaDialog",
  props: ['value', 'matricula'],
  data() {
    return {
      mapaMatriculaStatus: {
        'A': 'I',
        'I': 'A'
      },
      opened: false,
      data: {
        motivo: null,
        sts: null
      },
      headers: [
        {
          value: 'stsMatricula',
          text: 'Status',
          align: 'start',
          width: 100
        },
        {
          value: 'data',
          text: 'Data',
          align: 'start',
          width: 170
        },
        {
          text: 'Motivo',
          value: 'motivo',
          align: 'start'
        }
      ],
      loading: false,
      error: null,
      elements: [],
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
    }
  },
  watch: {
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh();
      }
    },
    value: {
      handler(v) {
        if(v) this.refresh();
      }
    },
    matricula: {
      immediate: true,
      handler(v) {
        if(!v) return;
        this.data = {
          sts: v.stsMatricula,
          motivo: null
        }
      }
    }
  },
  methods: {
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAll(this.pageOptions.page, {
          idMatricula: this.matricula.id
        });

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    async gravar() {
      if(this.$refs.form.validate()) {
        this.loading = true;
        try {
          await create({
            idMatricula: this.matricula.id,
            motivo: this.data.motivo,
            stsMatricula: this.mapaMatriculaStatus[this.data.sts]
          });
          this.data.sts = this.mapaMatriculaStatus[this.data.sts];
          this.data.motivo = null;
          this.$refs.form.resetValidation();
          this.refresh();
        } catch (err) {
          this.error = true;
        } finally {
          this.loading = false;
        }
      }
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>